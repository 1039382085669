import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { campaignClient, getSessionClient } from "../../api";

const HeaderBA = () => {
    const navigate = useNavigate();

    async function doLogout() {
        try {
            const sessionKey = getSessionClient().sessionKey;
            const result = await campaignClient.call<{ error: string }>("logout", { sessionKey });
            if (result) {
                navigate("/login");
            }
        } catch (e) {}
    }
    return (
        <header className="header headerBA">
            <div className="header__inner">
                <img src="/assets/images/safeway_logo_2.svg" alt="Logo" className="Logo" width={120} />
            </div>
            <div className="headerMenu">
                <ul>
                    <li>
                        <Link to="/portal">Prize Redemption</Link>
                    </li>
                    <li>
                        <button
                            onClick={() => {
                                doLogout();
                            }}>
                            Logout
                        </button>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default HeaderBA;
