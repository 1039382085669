import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { canadianPostalCodeRegex, emailRegex, looseNorthAmericanPhoneRegex, stringRegex } from "../../../helpers/regexes";
import { useEffect, useState } from "react";
import { campaignClient, getSessionClient, setSessionClient } from "../../../api";
import Popup from "../../../Components/PopUp/PopIp";
import InputMask from "react-input-mask";
import "./css.css";
import { luhn_validate } from "../../../helpers/lahnAlgorithm";
import Loading from "../../../Components/Loading";
import { Modal } from "react-bootstrap";
import Contest from "../Contest";

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

type RegisterFormData = {
    email: string;
    firstName: string;
    lastName: string;
    postal: string;
    phone: string;
    rules: boolean;
    rules1: boolean;
    background: string;
    sceneNumber?: string;
    optin1?: boolean;
    optin2?: boolean;
    optin3?: boolean;
};

export default function Registration() {
    const BackgroundOptions = [
        { value: "selectProfile1", title: "Background 1 - Kate" },
        { value: "selectProfile2", title: "Background 2 - Phil" },
        { value: "selectProfile3", title: "Background 3 - Stefan" },
    ];
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm<RegisterFormData>();
    const [formData, setFormData] = useState<RegisterFormData>();
    const [sceneNumberConfirmation, setSceneNumberConfirmation] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [showScene, setShowScene] = useState(true);
    const [openRules, setOpenRules] = useState(false);

    useEffect(() => {
        setSessionClient(campaignClient.createSession());
    }, []);

    async function confirmScene(data: RegisterFormData) {
        if (showScene) {
            //validate scene number
            const sceneNumber = data.sceneNumber && data.sceneNumber.replace(/\s/g, "");
            if (!luhn_validate(sceneNumber)) {
                setError("sceneNumber", {
                    type: "validate",
                    message: "Please enter a valid scene+ number.",
                });
                return;
            }
            setFormData(data);
            setSceneNumberConfirmation(true);
        } else {
            setFormData(data);
            handleRegister(data);
        }
    }

    async function handleRegister(data: RegisterFormData | undefined) {
        if (submitting) {
            return;
        }

        setSubmitting(true);
        setSubmissionError(false);
        if (data) {
            try {
                const { sessionKey, error, prize, message, code }: any = await getSessionClient()
                    .createParticipantBuilder()
                    .setEmail(data.email)
                    .setFirstName(data.firstName)
                    .setLastName(data.lastName)
                    .setPostal(data.postal)
                    .setPhone(data.phone)
                    .setPreferredLanguage("en")
                    .addMetadata({
                        rules: data.rules,
                        rules1: data.rules1,
                        sceneNumber: data?.sceneNumber && sceneNumberConfirmation ? data.sceneNumber.replace(/\s/g, "") : "",
                        optin1: data.optin1,
                        background: data.background,
                    })
                    .call<{ sessionKey?: string; error?: string; message?: string; code?: string }>("register");

                if (code === "email_resubmitted") {
                    setSceneNumberConfirmation(false);
                    window.vex.dialog.alert({
                        unsafeMessage: "Sorry, this email has already been registered.",
                    }); // already registered
                } else if (code && message) {
                    setSceneNumberConfirmation(false);
                    window.vex.dialog.alert({
                        unsafeMessage: message,
                    });
                } else if (error && message) {
                    setSceneNumberConfirmation(false);
                    window.vex.dialog.alert({
                        unsafeMessage: message,
                    });
                } else if (sessionKey) {
                    const winnerName = data.firstName + "%20" + data.lastName;

                    navigate("/thankyou", {
                        state: { email: data.email, background: data.background, prize },
                    });
                } else {
                    setSubmissionError(true);
                }
            } catch {
                setSubmissionError(true);
            }
        }
        setSubmitting(false);
    }

    return (
        <div className="container-fluid">
            {submitting && <Loading />}
            {sceneNumberConfirmation && !submitting && (
                <div className="row no-gutters">
                    <Popup
                        isOpen={sceneNumberConfirmation}
                        closeModal={() => setSceneNumberConfirmation(false)}
                        action1={() => handleRegister(formData)}
                        header="Please verify this Scene+ number is correct"
                        message={formData?.sceneNumber}
                        yesMessage="Continue"
                        cancelMessage="Go back"
                    />
                </div>
            )}
            <Modal show={openRules} onHide={() => {}} centered>
                <div
                    className="popup"
                    style={{
                        padding: "3rem 1rem 3rem 2rem",
                    }}>
                    <div className="popup__inner">
                        <div className="popup__head">
                            <h4>Contest rules</h4>
                        </div>
                        <div className="popup__body">
                            <Contest />
                        </div>
                        <div className="popup__foot">
                            <button
                                className="btn"
                                onClick={() => {
                                    setOpenRules(false);
                                    setValue("rules1", true);
                                }}>
                                I agree to the contest rules
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <div className="content">
                        <h1 style={{ textAlign: "center" }}>Welcome</h1>
                        <p style={{ textAlign: "center", fontWeight: "bold" }}>Please enter your info below.</p>
                        <div className="formContent">
                            <form onSubmit={handleSubmit(confirmScene)}>
                                <div className="form-group">
                                    <label>
                                        Email <span>*</span>
                                    </label>
                                    <input
                                        className="form-control email"
                                        placeholder="Email"
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message: "Please enter your email.",
                                            },
                                            pattern: {
                                                value: emailRegex,
                                                message: "Please enter a valid email.",
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <p className="error-message">
                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.email.message}
                                        </p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>
                                        First name <span>*</span>
                                    </label>
                                    <input
                                        className="form-control name"
                                        placeholder="First name"
                                        {...register("firstName", {
                                            required: {
                                                value: true,
                                                message: "Please enter your first name.",
                                            },
                                            pattern: {
                                                value: stringRegex,
                                                message: "Please enter a valid first name.",
                                            },
                                        })}
                                    />
                                    {errors.firstName && (
                                        <p className="error-message">
                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.firstName.message}
                                        </p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>
                                        Last name <span>*</span>
                                    </label>
                                    <input
                                        className="form-control email"
                                        placeholder="Last name"
                                        {...register("lastName", {
                                            required: {
                                                value: true,
                                                message: "Pease enter your last name.",
                                            },
                                            pattern: {
                                                value: stringRegex,
                                                message: "Please enter a valid last name.",
                                            },
                                        })}
                                    />
                                    {errors.lastName && (
                                        <p className="error-message">
                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.lastName.message}
                                        </p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>
                                        Postal code <span>*</span>
                                    </label>
                                    <input
                                        className="form-control email"
                                        placeholder="Postal Code"
                                        {...register("postal", {
                                            required: {
                                                value: true,
                                                message: "Please enter your postal code.",
                                            },
                                            pattern: {
                                                value: canadianPostalCodeRegex,
                                                message: "Please enter a valid postal code.",
                                            },
                                        })}
                                    />
                                    {errors.postal && (
                                        <p className="error-message">
                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.postal.message}
                                        </p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>
                                        Phone number <span>*</span>
                                    </label>
                                    <input
                                        className="form-control email"
                                        placeholder="Phone Number"
                                        {...register("phone", {
                                            required: {
                                                value: true,
                                                message: "Please enter your phone number.",
                                            },
                                            pattern: {
                                                value: looseNorthAmericanPhoneRegex,
                                                message: "Please enter a valid phone number.",
                                            },
                                        })}
                                    />
                                    {errors.phone && (
                                        <p className="error-message">
                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.phone.message}
                                        </p>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label>
                                        Background <span>*</span>
                                    </label>
                                    <select
                                        className="form-control email"
                                        {...register("background", {
                                            required: {
                                                value: true,
                                                message: "Please select background.",
                                            },
                                        })}>
                                        <option value="">Select background</option>
                                        {BackgroundOptions.map((item, i) => (
                                            <option value={item.value} key={i}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.background && (
                                        <p className="error-message">
                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.background.message}
                                        </p>
                                    )}
                                </div>

                                <small className="lblMand">
                                    <span className="mand">*</span> Required field
                                </small>
                                <div className="checkbox cssBased">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="chkBox"
                                            {...register("rules1", {
                                                required: {
                                                    value: true,
                                                    message: "You must agree to the Full contest Rules & Regulations in order to register.",
                                                },
                                            })}
                                        />
                                        <span className="cr">
                                            <i className="cr-icon fa fa-check" id="chkBox" />
                                        </span>
                                        <p>
                                            By clicking this box, you agree to have read and will abide by the{" "}
                                            <span className="contests" onClick={() => setOpenRules(true)}>
                                                Full contest Rules & Regulations.
                                            </span>
                                            <span className="mand">*</span>
                                        </p>
                                    </label>
                                </div>
                                {errors.rules1 && (
                                    <p className="error-message">
                                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.rules1.message}
                                    </p>
                                )}
                                <div className="checkbox cssBased">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="chkBox"
                                            {...register("optin1", {
                                                required: {
                                                    value: true,
                                                    message: "You must agree to receive electronic communications in order to register.",
                                                },
                                            })}
                                        />
                                        <span className="cr">
                                            <i className="cr-icon fa fa-check" id="chkBox" />
                                        </span>
                                        <p>
                                            Yes, I’d like to subscribe to marketing and promotional electronic communications. You can opt out at any time.
                                            <span className="mand">*</span>
                                        </p>
                                    </label>
                                </div>
                                {errors.optin1 && (
                                    <p className="error-message">
                                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.optin1.message}
                                    </p>
                                )}

                                <div className="checkbox cssBased">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="chkBox"
                                            {...register("rules", {
                                                required: {
                                                    value: true,
                                                    message: "You must agree to the Official Program Terms in order to register.",
                                                },
                                            })}
                                        />
                                        <span className="cr">
                                            <i className="cr-icon fa fa-check" id="chkBox" />
                                        </span>
                                        <p>
                                            By clicking, you agree to Terms of Use and Privacy Policy.
                                            <span className="mand">*</span>
                                        </p>
                                    </label>
                                </div>
                                {errors.rules && (
                                    <p className="error-message">
                                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.rules.message}
                                    </p>
                                )}
                                <div className="form-group">
                                    <p style={{ fontSize: "18px" }}>Do you have a Scene+ card?</p>

                                    <div className="radio cssBased">
                                        <label>
                                            <input type="radio" className="radio" name="scene" defaultChecked={showScene} onClick={() => setShowScene(true)} />
                                            <span className="cr">
                                                <i className="cr-icon fa fa-check-circle" />
                                            </span>
                                            <p>Yes, add my Scene+ card to my profile.</p>
                                        </label>
                                    </div>
                                    {showScene && (
                                        <div className="form-group">
                                            {/* <label>Scene+ number</label> */}
                                            <InputMask
                                                {...register("sceneNumber", {
                                                    minLength: {
                                                        value: 20,
                                                        message: "Please enter a valid scene+ number.",
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: "Please enter a valid scene+ number.",
                                                    },
                                                    pattern: {
                                                        value: /^[\d+\s]*$/,
                                                        message: "Please enter a valid scene+ number.",
                                                    },
                                                })}
                                                defaultValue="604646"
                                                mask="999999 999 999 999 9"
                                                maskChar="*"
                                            />
                                            {errors.sceneNumber && (
                                                <p className="error-message">
                                                    <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.sceneNumber.message}
                                                </p>
                                            )}
                                            <p>
                                                By entering my Scene+ Card number, I consent to Sobeys Capital Inc. using information from my Scene+ Account to send me personalized
                                                offers.
                                            </p>
                                        </div>
                                    )}
                                    <div className="radio cssBased">
                                        <label>
                                            <input type="radio" className="chkBox" name="scene" onClick={() => setShowScene(false)} />
                                            <span className="cr">
                                                <i className="cr-icon fa fa-check-circle" />
                                            </span>
                                            <p>
                                                No,{" "}
                                                <a style={{ color: "#404040" }} href="https://www.sceneplus.ca/register" target="_blank" rel="noopener noreferrer">
                                                    click here
                                                </a>{" "}
                                                to sign up for a Scene+ card.
                                            </p>
                                        </label>
                                    </div>
                                    <p>You will receive an email with a code to finish setting up your account. Please check the email account you used to setup your account.</p>
                                </div>

                                <button type="submit" className="btn">
                                    Submit
                                </button>

                                <p className="legal">
                                    † By checking this box and clicking submit I agree to receiving electronic messages from Sobeys Capital Incorporated, its affiliates and/or
                                    business brands and marketing partners. I understand that I can withdraw consent at any time. Contact us at privacy@sobeys.com or 115 King
                                    Street, Stellarton, Nova Scotia, B0K 1S0
                                </p>

                                {submissionError && (
                                    <>
                                        <br />
                                        {submissionError && (
                                            <p className="error-message">
                                                <i className="fas fa-exclamation-circle" /> Error connecting to the server, please try again
                                            </p>
                                        )}
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
