import React from "react"
import { Modal } from "react-bootstrap"

const Popup = ({
  isOpen,
  closeModal,
  header,
  message,
  action1,
  yesMessage,
  cancelMessage,
}: {
  isOpen: boolean
  closeModal: React.MouseEventHandler
  header: string
  message: string | undefined
  action1?: React.MouseEventHandler
  action2?: React.MouseEventHandler
  yesMessage?: string
  cancelMessage?: string
}) => {
  return (
    <>
      <Modal show={isOpen} onHide={() => {}} centered >
        <Modal.Body style={{backgroundColor: "white"}}>
          <div style={{ textAlign: "center" }}>
            <h4>
              <b>{header}</b>
            </h4>
            <p
              style={{
                color: "#000000",
                fontSize: "30px",
                fontWeight: "900",
              }}>
              {message}
            </p>
          </div>

          <button type="button" className="btn btn-primary small" onClick={action1}>
            {yesMessage}
          </button>

          <button
            type="button"
            className="btn grey"
            onClick={closeModal}
            style={{ marginTop: "25px" }}>
            {cancelMessage}
          </button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Popup
