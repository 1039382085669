import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { canadianPostalCodeRegex, emailRegex, looseNorthAmericanPhoneRegex, stringRegex, stringRegexwithspace } from "../../../helpers/regexes";
import SignaturePad from "react-signature-canvas";
import { campaignClient, getSessionClient } from "../../../api";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Loading from "../../../Components/Loading";
import disableBrowserHistory, { b64toBlob } from "../../../helpers/utils";
import HeaderBA from "../../../Components/HeaderBA";
import OptionsProvinces from "../../../Components/ProvincesEn";
import DeclarationEn from "./declarationEn";
import { Modal } from "react-bootstrap";

type STQForm = {
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    address2: string;
    city: string;
    province: string;
    postal: string;
    phone: string;
    answer: string;
    rules: boolean;
};
declare const window: Window &
    typeof globalThis & {
        vex: any;
    };
const PrizeClaim = () => {
    disableBrowserHistory();
    const navigate = useNavigate();
    const { pk } = useParams();
    const sessionKey = getSessionClient().sessionKey;
    const { t } = useTranslation();

    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const signCanvas = React.useRef() as React.MutableRefObject<any>;
    const [signImageUrl, setSignImageUrl] = useState<File>();

    const clear = () => {
        signCanvas.current.clear();
        setSignImageUrl(undefined);
    };
    async function getUserDetails() {
        if (submitting) {
            return;
        }
        setSubmitting(true);

        try {
            const { sessionKey } = getSessionClient();
            const userData = await campaignClient.call<STQForm>("getWinner", {
                sessionKey,
                prizeWinnerKey: pk,
            });
            if (userData?.firstName) {
                // setUserDetails(userData);
                reset(userData);
            }
            setSubmitting(false);
        } catch {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        getUserDetails();
    }, []);
    
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors, isDirty },
    } = useForm<STQForm>({ mode: "onTouched" });

    const handleRegister = async (data: STQForm) => {
        if (submitting || signCanvas?.current?.isEmpty()) {
            return;
        }
        setSubmitting(true);
        try {
            const uploadKey = await saveSignature(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

            if (!uploadKey) {
                setSubmitting(false);
                setErrorMessage("Something went wrong. Please try again.");
                return;
            }

            const { sessionKey } = getSessionClient();
            const { claimed, error, message } = await campaignClient.call<{
                claimed: boolean;
                error: string;
                message: string;
            }>("prizeClaim", {
                sessionKey,
                prizeWinnerKey: pk,
                signature: uploadKey,
                ...data,
            });
            if (claimed) {
                navigate("/portal/claimed");
            } else if (error) {
                setErrorMessage(message);
            }
        } catch (e) {
            setErrorMessage("Something went wrong. Please try again.");
        }

        setSubmitting(false);
    };

    const getFileUploadUrl = async (sessionKey: string, type: string) => {
        try {
            let { uploadUrl, key } = await campaignClient.call<{
                uploadUrl: string;
                key: string;
            }>("getFileUploadUrlForUser", {
                sessionKey,
                filename: "signature",
                contentType: ".jpg",
            });

            return { uploadUrl, urlKey: key };
        } catch (e) {
            setSubmitting(false);

            console.log(e);
        }
    };

    const saveSignature = async (sign: any) => {
        setSubmitting(true);
        setSignImageUrl(sign);

        const res = await getFileUploadUrl(sessionKey, typeof sign);

        // handleUpload()

        if (sign && res?.uploadUrl) {
            const block = sign.split(";");
            const contentType = block[0].split(":")[1];
            const realData = block[1].split(",")[1];
            const blob = b64toBlob(realData, contentType);
            try {
                const data = await blob.arrayBuffer();
                axios
                    .put(res.uploadUrl, data, {
                        onUploadProgress: function (progressEvent) {
                            // const percentCompleted = Math.round(
                            //   (progressEvent.loaded * 100) / progressEvent.total
                            // );
                        },
                        headers: {
                            "Content-Type": typeof blob,
                        },
                    })
                    .then(async (res) => {
                        if (res.status === 200) {
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                setSubmitting(false);
            } catch {
                setSubmitting(false);

                console.log("error while uploading signature");
            }
            return res.urlKey;
        }
    };

    return (
        <>
            <Modal show={!!errorMessage} onHide={() => {}} centered>
                <div
                    className="popup"
                    style={{
                        padding: "3rem 1rem 3rem 2rem",
                    }}>
                    <div className="popup__inner">
                        <div className="popup__body">
                            <strong>{errorMessage}</strong>
                        </div>
                        <div className="popup__foot">
                            <button
                                className="btn"
                                style={{ minWidth: "10rem", margin: "0 auto" }}
                                onClick={() => {
                                    setErrorMessage("");
                                }}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <HeaderBA />{" "}
            <div className="container-fluid">
                {submitting && <Loading />}

                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="content">
                            <form onSubmit={handleSubmit(handleRegister)} autoComplete="off">
                                <div className="form__head">
                                    <h3>Complete the skill-testing question</h3>
                                    <small>* Indicates requires field</small>
                                </div>
                                {/* /.form__head */}
                                <div className="form__body">
                                    <div className="form__row">
                                        <div className={`form__bar ${errors.firstName ? "is-required" : ""}`}>
                                            <label htmlFor="first-name" className="form__label">
                                                <span className="form__asterisk">*</span> {t("stq.3")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <input
                                            type="text"
                                            className="field"
                                            {...register("firstName", {
                                                required: {
                                                    value: true,
                                                    message: t("stq.5"),
                                                },
                                                pattern: {
                                                    value: stringRegex,
                                                    message: t("stq.6"),
                                                },
                                            })}
                                            placeholder={t("stq.4")}
                                        />
                                        {errors.firstName && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.firstName.message}{" "}
                                            </p>
                                        )}
                                    </div>
                                    {/* /.form__row */}
                                    <div className="form__row">
                                        <div className={`form__bar ${errors.lastName ? "is-required" : ""}`}>
                                            <label htmlFor="last-name" className="form__label">
                                                <span className="form__asterisk">*</span> {t("stq.7")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <input
                                            type="text"
                                            className="field"
                                            {...register("lastName", {
                                                required: {
                                                    value: true,
                                                    message: t("stq.9"),
                                                },
                                                pattern: {
                                                    value: stringRegexwithspace,
                                                    message: t("stq.10"),
                                                },
                                            })}
                                            placeholder={t("stq.8")}
                                        />
                                        {errors.lastName && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.lastName.message}{" "}
                                            </p>
                                        )}
                                    </div>
                                    {/* /.form__row */}
                                    <div className="form__row">
                                        <div className={`form__bar ${errors.email ? "is-required" : ""}`}>
                                            <label htmlFor="email" className="form__label">
                                                <span className="form__asterisk">*</span> {t("stq.11")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <input
                                            type="text"
                                            className="field"
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: t("stq.13"),
                                                },
                                                pattern: {
                                                    value: emailRegex,
                                                    message: t("stq.14"),
                                                },
                                            })}
                                            placeholder={t("stq.12")}
                                        />
                                        {errors.email && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.email.message}{" "}
                                            </p>
                                        )}
                                    </div>
                                    {/* /.form__row */}
                                    <div className="form__row">
                                        <div className={`form__bar ${errors.address ? "is-required" : ""}`}>
                                            <label htmlFor="address" className="form__label">
                                                <span className="form__asterisk">*</span> {t("stq.15")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <input
                                            type="text"
                                            className="field"
                                            {...register("address", {
                                                required: {
                                                    value: true,
                                                    message: t("stq.17"),
                                                },
                                            })}
                                            placeholder={t("stq.16")}
                                        />
                                        {errors.address && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.address.message}{" "}
                                            </p>
                                        )}
                                    </div>
                                    {/* /.form__row */}
                                    <div className="form__row">
                                        <div className={`form__bar`}>
                                            <label htmlFor="apartment" className="form__label">
                                                {t("stq.19")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <input type="text" className="field" {...register("address2")} placeholder={t("stq.19")} />
                                    </div>
                                    <div className="form__row">
                                        <div className={`form__bar ${errors.city ? "is-required" : ""}`}>
                                            <label htmlFor="city" className="form__label">
                                                <span className="form__asterisk">*</span> {t("stq.20")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <input
                                            type="text"
                                            className="field"
                                            {...register("city", {
                                                required: {
                                                    value: true,
                                                    message: t("stq.22"),
                                                },
                                            })}
                                            placeholder={t("stq.21")}
                                        />
                                        {errors.city && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.city.message}{" "}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form__row">
                                        <div className={`form__bar ${errors.province ? "is-required" : ""}`}>
                                            <label htmlFor="province" className="form__label">
                                                <span className="form__asterisk">*</span> {t("stq.24")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <div className="select">
                                            <select
                                                id="field-select-1"
                                                {...register("province", {
                                                    required: {
                                                        value: true,
                                                        message: t("stq.26"),
                                                    },
                                                })}>
                                                <option value="">{t("stq.25")}</option>
                                                {OptionsProvinces()}
                                            </select>
                                        </div>
                                        {errors.province && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.province.message}{" "}
                                            </p>
                                        )}
                                    </div>
                                    {/* /.form__row */}
                                    <div className="form__row">
                                        <div className={`form__bar ${errors.postal ? "is-required" : ""}`}>
                                            <label htmlFor="postal-code" className="form__label">
                                                <span className="form__asterisk">*</span> {t("stq.27")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <input
                                            type="text"
                                            className="field"
                                            {...register("postal", {
                                                required: {
                                                    value: true,
                                                    message: t("stq.29"),
                                                },
                                                pattern: {
                                                    value: canadianPostalCodeRegex,
                                                    message: t("stq.30"),
                                                },
                                            })}
                                            placeholder={t("stq.28")}
                                        />
                                        {errors.postal && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.postal.message}{" "}
                                            </p>
                                        )}
                                    </div>
                                    {/* /.form__row */}
                                    <div className="form__row">
                                        <div className={`form__bar ${errors.phone ? "is-required" : ""}`}>
                                            <label htmlFor="phone-number" className="form__label">
                                                <span className="form__asterisk">*</span> {t("stq.31")}
                                            </label>
                                            {/* /.form__label */}
                                        </div>
                                        <input
                                            type="text"
                                            className="field"
                                            {...register("phone", {
                                                required: {
                                                    value: true,
                                                    message: t("stq.33"),
                                                },
                                                pattern: {
                                                    value: looseNorthAmericanPhoneRegex,
                                                    message: t("stq.34"),
                                                },
                                            })}
                                            placeholder={t("stq.32")}
                                        />
                                        {/* /.form__controls */}{" "}
                                        {errors.phone && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" /> {errors.phone.message}
                                            </p>
                                        )}
                                    </div>
                                    {/* /.form__row */}
                                    {/* /.form__row */}
                                    <div className="form__row form__row--modify">
                                        <div className="form__entry">
                                            <h5>Your prize</h5>
                                            <p> One (1) Lunchbox </p>
                                            <h5>{t("stq.41")}</h5>
                                            <ul className="list-calculations">
                                                <li>
                                                    <span>{t("stq.42")}</span>
                                                    <span> 10 x 10</span>
                                                </li>
                                                <li>
                                                    <span>{t("stq.43")}</span>
                                                    <span>25</span>
                                                </li>
                                                <li>
                                                    <span>{t("stq.44")}</span>
                                                    <span>5</span>
                                                </li>
                                                <li>
                                                    <span>{t("stq.45")}</span>
                                                    <span>20</span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* /.form__entry */}
                                    </div>
                                    {/* /.form__row */}
                                    <div className="form__row form__row--alter" style={{ marginBottom: "0.5rem" }}>
                                        <div className={`form__bar ${errors.answer ? "is-required" : ""}`}>
                                            <label htmlFor="answer" className="form__label">
                                                {t("stq.46")} <span className="form__asterisk">*</span>{" "}
                                            </label>
                                            {/* /.form__label */}
                                        </div>

                                        <input
                                            className="field"
                                            onWheel={(e) => e.currentTarget.blur()}
                                            {...register("answer", {
                                                required: {
                                                    value: true,
                                                    message: t("stq.48"),
                                                },
                                                pattern: {
                                                    value: /^[5]$/,
                                                    message: t("stq.49"),
                                                },
                                            })}
                                            maxLength={1}
                                            placeholder={t("stq.47")}
                                        />
                                        {errors.answer && (
                                            <p className="error-message">
                                                <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />

                                                {errors.answer.message}
                                            </p>
                                        )}

                                        {/* /.form__hint--adjust */}
                                    </div>{" "}
                                    <span className="form__hint form__hint--adjust">
                                        <span className="form__asterisk">*</span>
                                        {t("stq.50")}
                                    </span>
                                    {/* /.form__row */}
                                    <div className="form__row form__row--offset">
                                        <div className="form__declaration">
                                            <h5>{t("stq.51")}</h5>
                                            <div className="checkbox cssBased">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        className="chkBox"
                                                        {...register("rules", {
                                                            required: {
                                                                value: true,
                                                                message: "You must agree to receive electronic communications in order to register.",
                                                            },
                                                        })}
                                                    />
                                                    <span className="cr">
                                                        <i className="cr-icon fa fa-check" id="chkBox" />
                                                    </span>
                                                    <p>I agree to the Declaration and Release.</p>
                                                </label>
                                            </div>
                                            {errors.rules && (
                                                <p className="error-message">
                                                    <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.rules.message}
                                                </p>
                                            )}
                                            <div className="form__declaration-entry">
                                                <DeclarationEn />
                                            </div>

                                            <p className={`form__bar ${signCanvas?.current?.isEmpty() && isDirty ? "is-required" : ""}`}>{t("stq.54")} *</p>
                                            {/* /.form__label */}
                                            <div className="signature">
                                                <SignaturePad
                                                    ref={signCanvas}
                                                    canvasProps={{
                                                        className: "signPad",
                                                        style: {
                                                            position: "relative",
                                                            display: "block",
                                                            zIndex: "99",
                                                            width: "100%",
                                                            // width: "300px",
                                                            // maxWidth:"00px",
                                                            minHeight: "80px",
                                                            height: "150px",
                                                            background: "#FFF",
                                                            border: "2px solid #DBDFE3",
                                                            borderRadius: "10px",

                                                            textDecoration: "none",
                                                        },
                                                        onClick: () => {
                                                            setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                                                        },
                                                        onChange: () => {
                                                            setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                                                        },
                                                        onTouchEnd: () => {
                                                            setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                                                        },
                                                    }}
                                                />
                                                {signCanvas?.current?.isEmpty() && isDirty && (
                                                    <p className="error-message">
                                                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                                        {t("stq.55")}
                                                    </p>
                                                )}
                                            </div>
                                            {/* /.signature */}
                                        </div>
                                        {/* /.form__declaration */}
                                    </div>
                                    {/* /.form__row */}
                                </div>
                                {/* /.form__body */}
                                <div className="form__actions stqAction">
                                    {signImageUrl && (
                                        <button onClick={clear} className="btn_cancel" style={{ minWidth: "10rem", margin: "0 auto" }} type="button">
                                            Clear signature
                                        </button>
                                    )}
                                    {/* /.btn btn--transparent */}
                                    <button type="submit" className="btn" style={{ minWidth: "10rem", margin: "0 auto" }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrizeClaim;
