import React, { useState, useEffect } from "react"
import { campaignClient, getSessionClient } from "../api"
import { Navigate } from "react-router-dom"

export type CampaignAuthRequiredProps = {
    children: React.ReactNode | React.ReactNode[]
}

export default function CampaignAuthRequired({ children }: CampaignAuthRequiredProps) {
    const sessionClient = getSessionClient()

    const [authed, setAuthed] = useState(null as boolean | null)

    async function checkAuth() {
        try {
            const sessionKey = sessionClient.sessionKey
            const auth = await campaignClient.call<{ error: string; authed: boolean }>("isBaLoggedin", { sessionKey });
             if (auth.authed !== authed) { setAuthed(auth.authed) }
        }
        catch (e) {
            console.log('Failed to contact API to check auth status')
            setAuthed(false)
        }
    }

    useEffect(() => {
        if (authed === null) { checkAuth() }
    })

    if (authed === false) {
        return <Navigate to="/login" replace />
    }
    if (authed === null) {
        return <></>
    }
    return <>{children}</>
}