import { useEffect, useState } from "react";
import { campaignClient, getSessionClient, setSessionClient } from "../../api";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import generateKey from "@spry/campaign-client/dist/generateKey.js";
import Loading from "../../Components/Loading";
import { Modal } from "react-bootstrap";
import { emailRegex } from "../../helpers/regexes";

type LoginData = {
    user: string;
    password: string;
};

export default function Login() {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginData>();

    const [loading, setLoading] = useState(false);

    const [authError, setAuthError] = useState("");

    async function checkAuth() {
        try {
            const sessionKey = getSessionClient().sessionKey;
            const auth = await campaignClient.call<{ error: string; authed: boolean }>("isBaLoggedin", { sessionKey });
            if (auth?.authed) {
                navigate("/portal");
            }
        } catch (e) {
            console.log("Failed to contact API to check auth status");
        }
    }

    useEffect(() => {
        checkAuth();
    }, []);

    async function handleLogin(data: LoginData) {
        setLoading(true);
        setAuthError("");

        try {
            const sessionKey = generateKey();

            const res = await campaignClient.call<{ message: string; error: string; authed: boolean }>("baLogin", { ...data, sessionKey });

            if (res.error && res.message) {
                setAuthError(res.message);
            } else if (!res.authed) {
                setAuthError("Password or Username is incorrect.");
            } else {
                campaignClient.recoverSession(sessionKey);
                setSessionClient(campaignClient.recoverSession(sessionKey));
                navigate("/portal");
            }

            setLoading(false);
        } catch (e: any) {
            setAuthError(e.message);
            setLoading(false);
        }
        setLoading(false);
    }

    return (
        <div className="container-fluid">
            {loading && <Loading />}

            <Modal show={!!authError} onHide={() => {}} centered>
                <div
                    className="popup"
                    style={{
                        padding: "3rem 1rem 3rem 2rem",
                    }}>
                    <div className="popup__inner">
                        <div className="popup__body">{authError}</div>
                        <div className="popup__foot">
                            <button
                                className="btn"
                                onClick={() => {
                                    setAuthError("");
                                }}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <div className="content">
                        <div style={{ textAlign: "center", marginTop: "5rem" }}>
                            {" "}
                            <img src="/assets/images/logo.svg" alt="Logo" className="Logo" width={250} /> <h1 style={{ marginTop: "3rem" }}>Login</h1>
                            <p style={{ fontWeight: "bold" }}>Please enter your login below.</p>
                        </div>

                        <div className="formContent">
                            <form onSubmit={handleSubmit(handleLogin)}>
                                <div className="form-group">
                                    <label>
                                        Username <span>*</span>
                                    </label>
                                    <input
                                        className="form-control email"
                                        placeholder="Username"
                                        {...register("user", {
                                            required: {
                                                value: true,
                                                message: "Please enter your username.",
                                            },
                                            // pattern: {
                                            //     value: emailRegex,
                                            //     message: "Please enter a valid email.",
                                            // },
                                        })}
                                    />
                                    {errors.user && (
                                        <p className="error-message">
                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.user.message}
                                        </p>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label>
                                        Password<span>*</span>
                                    </label>
                                    <input
                                        className="form-control email"
                                        placeholder="Password"
                                        {...register("password", {
                                            required: {
                                                value: true,
                                                message: "Pease enter your password.",
                                            },
                                        })}
                                    />
                                    {errors.password && (
                                        <p className="error-message">
                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.password.message}
                                        </p>
                                    )}
                                </div>
                                <div className="action">
                                    <button type="submit" className="btn" style={{ minWidth: "10rem", margin: "0 auto" }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
