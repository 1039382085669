import { Route } from "react-router-dom";
import Closed from "../Pages/Closed";
import ComingSoon from "../Pages/ComingSoon";
import Open from "../Pages/Open";
import RedemptionOnly from "../Pages/RedemptionOnly";
import Suspended from "../Pages/Suspended";
import Registration from "../Pages/Open/Registration";
import Thankyou from "../Pages/Open/Thankyou";
import "../i18n";
import Admin from "../Pages/Admin";
import Login from "../Pages/Login";
import WinnerSearch from "../Pages/Admin/WinnerSearch";
import PrizeClaim from "../Pages/Admin/PrizeClaim";
import PrizeClaimed from "../Pages/Admin/PrizeClaimed";
import Rules from "../Pages/Open/Rules";


export default (
    <>
        <Route path="/" element={<Open />}>
            <Route path="/" element={<Registration />} />

            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/rules" element={<Rules />} />
        </Route>
        <Route path="/login" element={<Login />} />

        <Route path="/portal" element={<Admin />}>
            <Route path="/portal" element={<WinnerSearch />} />
            <Route path="/portal/prizeClaim/:pk" element={<PrizeClaim />} />
            <Route path="/portal/claimed" element={<PrizeClaimed />} />
        </Route>

        <Route path="closed" element={<Closed />} />
        <Route path="coming" element={<ComingSoon />} />
        <Route path="redemption" element={<RedemptionOnly />} />
        <Route path="suspended" element={<Suspended />} />
    </>
);
