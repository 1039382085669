import { useState } from "react";

import Loading from "../../../Components/Loading";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { emailRegex } from "../../../helpers/regexes";
import { campaignClient, getSessionClient } from "../../../api";
import { useNavigate } from "react-router-dom";
import HeaderBA from "../../../Components/HeaderBA";

type SearchData = {
    user: string;
};

export default function WinnerSearch() {
    const defaultUserDetails = { firstName: "", lastName: "", prize: "", status: "", prizeWinnerKey: "" };
    const [errorMessage, setErrorMessage] = useState("");
    const [userDetails, setUserDetails] = useState(defaultUserDetails);
    const navigate = useNavigate();
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm<SearchData>();

    const [loading, setLoading] = useState(false);

    async function handleSearch(data: SearchData) {
        setLoading(true);
        setErrorMessage("");

        try {
            const sessionKey = getSessionClient().__sessionKey;

            const res = await campaignClient.call<{
                message: string;
                error: string;
                firstName: string;
                lastName: string;
                prize: string;
                status: "Unclaimed";
                prizeWinnerKey: string;
            }>("checkWinner", { email: data.user, sessionKey });

            if (res.error && res.message) {
                setErrorMessage(res.message);
            } else if (res.status !== "Unclaimed") {
                setErrorMessage("This prize has already been claimed");
            } else if (res.prizeWinnerKey && res.status === "Unclaimed") {
                reset();
                setUserDetails({ firstName: res.firstName, lastName: res.lastName, prize: res.prize, prizeWinnerKey: res.prizeWinnerKey, status: res.status });
            }

            setLoading(false);
        } catch (e: any) {
            setErrorMessage(e.message);
            setLoading(false);
        }
        setLoading(false);
    }

    return (
        <>
            <HeaderBA />
            <div className="container-fluid">
                {loading && <Loading />}

                <Modal show={!!errorMessage} onHide={() => {}} centered>
                    <div
                        className="popup"
                        style={{
                            padding: "3rem 1rem 3rem 2rem",
                        }}>
                        <div className="popup__inner">
                            <div className="popup__body">
                                <strong>{errorMessage}</strong>
                            </div>
                            <div className="popup__foot">
                                <button
                                    className="btn"
                                    style={{ minWidth: "10rem", margin: "0 auto" }}
                                    onClick={() => {
                                        setErrorMessage("");
                                    }}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="content">
                            {userDetails?.firstName && userDetails?.prizeWinnerKey ? (
                                <div>
                                    <div style={{ textAlign: "center", marginTop: "5rem" }}>
                                        <h1>Winner Details</h1>
                                        <p style={{ fontWeight: "bold", paddingTop: "1rem" }}>Please review the info below before continue to the skill-testing question</p>
                                        <table className="useDetailsTable">
                                            <tbody>
                                                <tr>
                                                    <td width={150}>
                                                        <p>
                                                            <strong>First name</strong>
                                                        </p>
                                                    </td>
                                                    <td width={250}>
                                                        <p>{userDetails.firstName}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width={150}>
                                                        <p>
                                                            <strong>Last name</strong>
                                                        </p>
                                                    </td>
                                                    <td width={250}>
                                                        <p>{userDetails.lastName}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width={150}>
                                                        <p>
                                                            <strong>Prize</strong>
                                                        </p>
                                                    </td>
                                                    <td width={250}>
                                                        <p>{userDetails.prize}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width={150}>
                                                        <p>
                                                            <strong>Status</strong>
                                                        </p>
                                                    </td>
                                                    <td width={250}>
                                                        <p>{userDetails.status}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="action">
                                        <button
                                            type="button"
                                            className="btn"
                                            style={{ minWidth: "10rem", margin: "0 auto" }}
                                            onClick={() => {
                                                navigate(`/portal/prizeClaim/${userDetails.prizeWinnerKey}`);
                                            }}>
                                            Continue
                                        </button>
                                        <button
                                            type="button"
                                            className="btn_cancel"
                                            style={{ minWidth: "10rem", margin: "0 auto" }}
                                            onClick={() => {
                                                setUserDetails(defaultUserDetails);
                                            }}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div style={{ textAlign: "center", marginTop: "5rem" }}>
                                        <h1 style={{ marginTop: "6rem" }}>Search for a winner</h1>
                                        <p style={{ fontWeight: "bold" }}>
                                            Enter the email of the winner and click submit. Once you find their prize win record, you can award the prize to them.
                                        </p>
                                    </div>

                                    <div className="formContent">
                                        <form onSubmit={handleSubmit(handleSearch)} autoComplete="off">
                                            <div className="form-group">
                                                <label>
                                                    Email <span>*</span>
                                                </label>
                                                <input
                                                    className="form-control email"
                                                    placeholder="Email"
                                                    {...register("user", {
                                                        required: {
                                                            value: true,
                                                            message: "Please enter your email.",
                                                        },
                                                        pattern: {
                                                            value: emailRegex,
                                                            message: "Please enter a valid email.",
                                                        },
                                                    })}
                                                />
                                                {errors.user && (
                                                    <p className="error-message">
                                                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="error_message" /> {errors.user.message}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="action">
                                                <button type="submit" className="btn" style={{ minWidth: "10rem", margin: "0 auto" }}>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
