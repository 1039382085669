import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import Contest from "../Contest";

const Rules = () => {
    return (
        <div className="container-fluid">
            <div className="col-sm-12 col-md-12">
                <div className="content paddingBottom">
                    <Contest />
                </div>
            </div>
        </div>
    );
};

export default Rules;
