import React from "react";

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <ul className="list-inline footerNav">
                <li>
                    <a href="https://www.safeway.ca/utilities/terms-of-use/" target="_blank" rel="noopener noreferrer">
                        Terms of Use
                    </a>
                </li>
                <li>
                    <a href="https://www.safeway.ca/utilities/privacy-policy/" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Footer;
