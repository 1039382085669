import dayjs from "dayjs";

export function b64toBlob(b64Data: string, contentType: string, sliceSize = 512) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

const disableBrowserHistory = () => {
    window.history.pushState(null, null || "", window.location.href);
    window.onpopstate = function (event) {
        window.history.pushState(null, "", window.location.href);
    };
};

export function isVisiblePhotoSubmissions({ banner, locationType, province }: { banner: string; locationType: string; province: string }) {
    const availableBanners = ["Sobeys", "Foodland", "Co-Op", "Safeway", "Thrifty", "IGA"];
    const unavailableProvince = "QC";
    if (province === unavailableProvince) return false;
    else if (locationType === "office" || (locationType === "store" && availableBanners.some((b) => b === banner))) return true;
    else return false;
}

export function WordCount(str: string) {
    const regExpEvents = /[?|!|.|,|:|<<|>>|<|>]/g;
    const stringLength = str.replace(regExpEvents, " ").replace(/\s\s+/g, " ").trim().split(" ").length;
    // console.log(stringLength);
    return stringLength;
}

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export default disableBrowserHistory;

export function isAfterDate() {
    return dayjs(new Date()).isAfter("2024-September-05");
}
