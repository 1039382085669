import { Outlet } from "react-router-dom";
import CampaignAuthRequired from "../../Components/CampaignAuthRequired";

export default function Admin() {
    return (
        <CampaignAuthRequired>
            <Outlet />
        </CampaignAuthRequired>
    );
}
