import { isAfterDate } from "../../../helpers/utils";

const Contest = () => {
    return (
        <>
            <h5 style={{ textAlign: "center", fontSize: "18px" }}>
                <p>SAFEWAY 53’ MFX FTD LUNCHBOX CONTEST 2024</p> <p>(the &ldquo;Contest&rdquo;)</p>
            </h5>

            <div className="rules">
                {isAfterDate() ? (
                    <>
                        <ol>
                            <li>
                                {" "}
                                <strong>Eligibility</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest is open only to legal residents of Alberta&nbsp;(the “Participating Province”) who have a valid Scene+ account, and who have reached the age
                            of majority in their province of residence on the date that they entered the Contest. Void where prohibited by law. Employees, directors, officers,
                            representatives, agents of Sobeys Capital Incorporated (the “Sponsor”), XMC Experiential Marketing (the “Administrator”) and each of their respective
                            parent companies, affiliates, franchisees, subsidiaries, distributors, representatives, advertising and promotional agencies, agents, sponsors, and any
                            entity that they contract with specific to the&nbsp;Contest, as well as the immediate family members, and any persons domiciled with any of the above
                            (whether related or not), are not eligible to win a prize.
                        </p>
                        <ol start={2}>
                            <li>
                                <strong>Contest Period</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest runs from September 7, 2024, 9:00am MDT and ends on September 8, 2024, 11:59pm MDT (the "Contest Period") that includes two different draw
                            dates (each a “Draw Date”).&nbsp;
                        </p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>
                                            <strong>Draw Date</strong>
                                        </p>
                                    </td>
                                    <td width={76}>
                                        <p>
                                            <strong>Prize Allocation </strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            <strong>Prize Description</strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>September 7, 2024</p>
                                    </td>
                                    <td width={76}>
                                        <p>100</p>
                                    </td>
                                    <td>
                                        <p>
                                            Each prize consists of one (1) Safeway branded lunchbox (included inside: two (2) note pads, one (1) Ice pack) (ARV: $19.00 CAD each).
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>September 8, 2024</p>
                                    </td>
                                    <td width={76}>
                                        <p>100</p>
                                    </td>
                                    <td>
                                        <p>
                                            Each prize consists of one (1) Safeway branded lunchbox (included inside: two (2) note pads, one (1) Ice pack) (ARV: $19.00 CAD each).
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ol start={3}>
                            <li>
                                <strong>How to Enter without Purchase</strong>
                            </li>
                        </ol>
                        <p>
                            NO PURCHASE IS NECESSARY. To enter the Contest, visit the MFX activation trailer (the “MFX Trailer”) at the Spruce Meadows grounds in Calgary, Alberta
                            during the Contest Period. Each attendee visiting the MFX Trailer and wishing to enter the Contest can scan the QR code with their own mobile device or
                            use the device that will be provided by a representative of the Sponsor or Administrator while onsite and complete the official Contest entry form (the
                            “Entry Form”). In the Entry Form you must provide your e-mail, first name, last name, phone number, postal code and registered Scene+ account number,
                            opt in to receive electronic marketing communications (you can opt out at any time), and check off the corresponding boxes on the Entry Form to be
                            entered, and read and accept the Contest Rules to gain one (1) entry into the Contest for your chance to win (an “Entry”). An internet connection using
                            data or Wi-Fi is required to complete the steps above.
                        </p>
                        <p>Limit of one (1) Entry, per e-mail address, per person, during the Contest Period.</p>
                        <ol start={4}>
                            <li>
                                {" "}
                                <strong>Prize Conditions </strong>
                            </li>
                        </ol>
                        <p>
                            <br /> The following conditions apply to the prizes (each a “Prize(s)”):
                        </p>
                        <ul>
                            <li>
                                The Prizes have no equivalent cash value, are non-transferable and no substitutions will be made. Prize must be accepted as awarded. The Sponsor
                                reserves the right to substitute any Prize for one of equal or greater value for any reason. You are responsible for all costs not expressly
                                described as being included as part of the Prize, such as any fee associated with the receipt and/or use of the Prize.&nbsp;
                            </li>
                            <li>You must sign the Sponsor’s release form (the “Release”) in order to receive and participate in the Prize.</li>
                            <li>Any difference between approximate retail value and the actual value of the Prize as taken will not be awarded.</li>
                        </ul>
                        <ol start={5}>
                            <li>
                                {" "}
                                <strong>Odds</strong>
                            </li>
                        </ol>
                        <p>The odds of winning a Prize depends on the total number of eligible Entries received by each Draw Date during the Contest Period.</p>
                        <ol start={6}>
                            <li>
                                {" "}
                                <strong>Draw Details</strong>
                            </li>
                        </ol>
                        <p>
                            Random draws will be conducted on site through an instant-prize randomization function by a representative of the Sponsor or Administrator located in
                            Calgary, Alberta from all eligible Entries received during the Contest Period (the “Draw”). The Draws will occur in accordance with the schedule in
                            Section 2. The Prizes will be awarded instantly on site at the MFX Trailer. You will need to be in attendance to be selected. You are only eligible to
                            win a Prize once.
                        </p>
                        <ol start={7}>
                            <li>
                                <strong>Release and Skill Testing Question</strong>
                            </li>
                        </ol>
                        <p>
                            In order to be declared a winner, you must correctly answer without assistance a mathematical skill testing question and sign a Release form. Upon
                            verification of the completion and correctness of the answer to the skill-testing question, and execution of the Release, you may be declared a winner.
                        </p>
                        <ol start={8}>
                            <li>
                                {" "}
                                <strong>Forfeit of Prize</strong>
                            </li>
                        </ol>
                        <p>
                            If you do not answer the skill-testing question correctly, do not sign the Release, or otherwise fail to comply with these Contest Rules, then the Prize
                            will be forfeited.
                        </p>
                        <ol start={9}>
                            <li>
                                {" "}
                                <strong>Use of Personal Information</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest and voluntarily providing your personal information as described in these Contest Rules, you agree to the collection, use and
                            disclosure by the Administrator, and its respective employees and/or authorized agents, of your personal information, for the purpose of administering
                            the Contest, including—but not limited to—contacting you with respect to the Contest if you are a selected entrant. Personally-identifiable information
                            will only be used to administer the Contest and for no other purpose, except as specifically outlined in these Contest Rules. Personally-identifiable
                            information will not be sold, shared or disclosed by the Administrator to any third party, other than to a third party engaged by the Administrator for
                            the purpose of administering the Contest, or if required by law with the exception that aggregated information may be used by or shared amongst the
                            Administrator and its subsidiaries and affiliates, or shared by the Administrator with third parties in accordance with our Privacy Commitment available
                            at https://www.sobeys.com/en/privacy-policy.
                        </p>
                        <ol start={10}>
                            <li>
                                {" "}
                                <strong>Publicity Consent</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest, you agree that if you are determined to be a winner, the Administrator and the Sponsor may use your name, comments, voice,
                            likeness, municipality of residence, your contest Entry and photo submission and picture in any advertising, promotion or publicity carried out now or
                            in the future, in any media without compensation or notice, and you grant to the Administrator and the Sponsor any and all rights to such use. You agree
                            to cooperate with the Administrator and the Sponsor in arranging for photographs or other forms of publicity and to be available for photographs or
                            other forms of publicity on reasonable notice.
                        </p>
                        <ol start={11}>
                            <li>
                                {" "}
                                <strong>Limitation of Liability / Release</strong>
                            </li>
                        </ol>
                        <p>
                            By participating in the Contest, and as a condition of acceptance of the Prize, you, your heirs, executors, administrators, successors and assigns,
                            release and forever discharge and hold harmless the Sponsor and the Administrator, and each of their direct and indirect parent companies, affiliates,
                            subsidiaries, successors, assigns, agents, advisors, franchisees, shareholders, partners, representatives, their advertising, promotion and fulfillment
                            agencies and each of their respective employees, officers, directors, agents and representatives (collectively, the “Released Parties”), from and
                            against any and all losses, damages (including, without limitation, direct, indirect, incidental, consequential or punitive damages), rights, claims,
                            actions, causes of action, personal injury, property damage or death, including without limitation all costs and liabilities of any kind including legal
                            fees on a substantial indemnity scale, hereinafter called “Claims” and including without limitation any Claims arising from any act of negligence of the
                            Released Parties, that you now have, or may hereafter have against the Released Parties directly or indirectly resulting or arising from: (i)
                            participation in the Contest, your Entry and/or the awarding, acceptance, possession, use, or misuse or enjoyment of any Prize, and where applicable,
                            traveling to, preparing for, or participating in, any Contest-related or Prize-related event or activity; or (ii) the publicity rights granted to the
                            Sponsor and the Administrator. You specifically acknowledge that you understand that there may be serious risks of bodily injury, death, or property
                            damage associated with the acceptance, possession, use and/or misuse of the Prize and attendance at any event or participation in certain Prize-related
                            activities, and you voluntarily assume these risks. You also agree to indemnify, defend, and hold harmless the Released Parties from any and all Claims
                            in respect of the foregoing.
                        </p>
                        <ol start={12}>
                            <li>
                                <strong>Compliance with Rules</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest, you agree to abide by the entire Contest Rules and the terms and conditions under which the Prize is awarded. Any decision made
                            by the Sponsor and/or the Administrator in respect of this Contest shall be final.
                        </p>
                        <ol start={13}>
                            <li>
                                {" "}
                                <strong>Technical Issues</strong>
                            </li>
                        </ol>
                        <p>
                            The Sponsor and Administrator are not responsible for any computer, online, telephone, hardware, software or technical limitations or malfunctions that
                            may occur (including but not limited to malfunctions that may affect the transmission or non-transmission of an Entry, or failure to receive an Entry),
                            nor for any incorrect or inaccurate information, whether caused by website users or by any of the equipment or programming associated with or utilized
                            in the Contest or by any technical or human error which may occur in the processing of Entries in the Contest, nor for any error, omission,
                            interruption, deletion, defect, delay in operation or transmission or receipt of Entry, communications line failure, theft or destruction or
                            unauthorized access to, or alteration of Entries; and, are not responsible for any problems, failures or technical malfunction of any telephone or
                            network lines, computer online systems, servers, providers, computer equipment, software, email, players, or browsers, on account of technical problems
                            or traffic congestion on the Internet, at any website or on account of any combination of the foregoing; and are not responsible for any injury or
                            damage to entrants or to any computer related injury or damage resulting from participating in downloading materials in this Contest.
                        </p>
                        <p>
                            In the event that, due to a printing, manufacturing, mechanical or other error of any kind or nature whatsoever, more Prizes are claimed than are
                            intended to be awarded according to these Contest Rules, Prizes will stop being awarded when the Administrator becomes aware of an error respecting the
                            number of Prizes and the Administrator reserves the right, in their sole and absolute discretion, to conduct a random draw from amongst all eligible and
                            approved Prize claimants whose claims have not yet been redeemed to award the remaining number of advertised Prizes. In no event will the Sponsor or
                            Administrator be liable for more than the stated number of Prizes contained in these Contest Rules.
                        </p>
                        <ol start={14}>
                            <li>
                                {" "}
                                <strong>Rights to Terminate/Modify/Suspend Contest</strong>
                            </li>
                        </ol>
                        <p>
                            The Administrator and Sponsor retain the right, in their absolute discretion, to suspend, modify or terminate any aspect of the Contest (including, but
                            not limited to these Contest Rules) at any time. The Administrator and Sponsor may make substitutions of equivalent kind or value in the event of the
                            unavailability of the Prize (or component thereof) for any reason whatsoever. The Administrator and Sponsor may terminate or withdraw this Contest at
                            any time by posting a notice on our website. If the Contest is terminated on any day prior to the normal Contest end date (the “Termination Date”), the
                            Administrator and Sponsor may determine the winners from all eligible Entries received as of 12:00PM MDT on the Termination Date (which winners shall be
                            randomly selected in accordance with these Contest Rules).
                        </p>
                        <ol start={15}>
                            <li>
                                {" "}
                                <strong>General Disqualification</strong>
                            </li>
                        </ol>
                        <p>
                            Any attempt made by an entrant or other individual or entity, to deliberately damage, manipulate or undermine the legitimate operation of the Contest,
                            including but not limited to any fraudulent claims, is a violation of the law. The Administrator and Sponsor reserve the right to seek remedies and
                            damages from any such individual or entity which makes any such attempt. Any entrant who, in the opinion of the Administrator, is determined to be
                            engaging in any of the foregoing activities will be immediately disqualified from the Contest.
                        </p>
                        <ol start={16}>
                            <li>
                                {" "}
                                <strong>Role of Parties</strong>
                            </li>
                        </ol>
                        <p>Sobeys Capital Incorporated is the Sponsor and XMC Experiential Marketing is the Administrator of this Contest.</p>
                        <ol start={17}>
                            <li>
                                {" "}
                                <strong>Trademark</strong>
                                <strong>s</strong>
                            </li>
                        </ol>
                        <p>All other Trademarks are the property of their respective owners.</p>
                        <ol start={18}>
                            <li>
                                {" "}
                                <strong>Jurisdiction</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest is governed by the laws of Alberta and the laws of Canada applicable therein, and is subject to all applicable federal, provincial and
                            municipal laws and regulations and is void where prohibited by law. Participation constitutes your full and unconditional agreement to these Contest
                            Rules and the decisions of the Sponsor and Administrator, which are final and binding in all matters related to the Contest. By entering the Contest,
                            you agree that the courts of Alberta, Canada shall have jurisdiction to entertain any action or other legal proceedings based on any provision of this
                            Contest’s Rules or the running of this Contest.
                        </p>
                    </>
                ) : (
                    <>
                        <ol>
                            <li>
                                <strong>Eligibility</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest is open only to legal residents of British Columbia (the “Participating Province”) who have a valid Scene+ account, and who have reached the
                            age of majority in their province of residence on the date that they entered the Contest. Void where prohibited by law. Employees, directors, officers,
                            representatives, agents of Sobeys Capital Incorporated (the “Sponsor”), XMC Experiential Marketing (the “Administrator”) and each of their respective
                            parent companies, affiliates, franchisees, subsidiaries, distributors, representatives, advertising and promotional agencies, agents, sponsors, and any
                            entity that they contract with specific to the&nbsp;Contest, as well as the immediate family members, and any persons domiciled with any of the above
                            (whether related or not), are not eligible to win a prize.
                        </p>
                        <ol start={2}>
                            <li>
                                <strong>Contest Period</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest runs from August 31, 2024, 11:00 AM PDT and ends on September 2, 2024, 11:00 PM PDT (the "Contest Period"), that includes three different
                            draw dates (each a “Draw Date”)
                        </p>
                        <table width={482}>
                            <tbody>
                                <tr>
                                    <td width={150}>
                                        <p>
                                            <strong>Draw Date</strong>
                                        </p>
                                    </td>
                                    <td width={76}>
                                        <p>
                                            <strong>Prize Allocation </strong>
                                        </p>
                                    </td>
                                    <td width={255}>
                                        <p>
                                            <strong>Prize Description</strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width={150}>
                                        <p>August 31, 2024</p>
                                    </td>
                                    <td width={76}>
                                        <p>200</p>
                                    </td>
                                    <td width={255}>
                                        <p>
                                            Each prize consists of one (1) Safeway branded lunchbox (included inside: two (2) note pads, one (1) Ice pack) (ARV: $19.00 CAD each).
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width={150}>
                                        <p>September 1, 2024</p>
                                    </td>
                                    <td width={76}>
                                        <p>200</p>
                                    </td>
                                    <td width={255}>
                                        <p>
                                            Each prize consists of one (1) Safeway branded lunchbox (included inside: two (2) note pads, one (1) Ice pack) (ARV: $19.00 CAD each).
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width={150}>
                                        <p>September 2, 2024</p>
                                    </td>
                                    <td width={76}>
                                        <p>200</p>
                                    </td>
                                    <td width={255}>
                                        <p>Each prize consists of one (1) Safeway branded lunchbox (included inside: two (2) note pads, one (1) Ice pack) (ARV: $19.00 CAD each)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <ol start={3}>
                            <li>
                                <strong>How to Enter without Purchase</strong>
                            </li>
                        </ol>
                        <p>
                            NO PURCHASE IS NECESSARY. To enter the Contest, visit the MFX activation trailer (the “MFX Trailer”) located at the Pacific National Exhibition (PNE) in
                            Vancouver, British Columbia, during the Contest Period. Each attendee visiting the MFX Trailer and wishing to enter the Contest can scan the QR code
                            with their own mobile device or use the device that will be provided by a representative of the Sponsor or Administrator while onsite and complete the
                            official Contest entry form (the “Entry Form”). In the Entry Form you must provide your e-mail, first name, last name, phone number, postal code and
                            registered Scene+ account number, opt in to receive electronic marketing communications (you can opt out at any time), and, check off the corresponding
                            boxes on the form to be entered, and read and accept the Contest Rules to gain one (1) entry into the Contest for your chance to win (an “Entry”). An
                            internet connection using data or Wi-Fi is required to complete the steps above.
                        </p>
                        <p>Limit of one (1) Entry, per e-mail address, per person, during the Contest Period.</p>
                        <ol start={4}>
                            <li>
                                <strong>Prize Conditions</strong>
                            </li>
                        </ol>
                        <p>
                            <br /> The following conditions apply to the prizes (each a “Prize(s)”):
                        </p>
                        <ul>
                            <li>
                                The Prizes have no equivalent cash value, are non-transferable and no substitutions will be made. Prize must be accepted as awarded. The Sponsor
                                reserves the right to substitute any Prize for one of equal or greater value for any reason. You are responsible for all costs not expressly
                                described as being included as part of the Prize, such as any fee associated with the receipt and/or use of the Prize.&nbsp;
                            </li>
                            <li>You must sign the Sponsor’s release form (the “Release”) in order to receive and participate in the Prize.</li>
                            <li>Any difference between approximate retail value and the actual value of the Prize as taken will not be awarded.</li>
                        </ul>
                        <ol start={5}>
                            <li>
                                <strong>Odds</strong>
                            </li>
                        </ol>
                        <p>The odds of winning a Prize depends on the total number of eligible Entries received by each Draw Date during the Contest Period.</p>
                        <ol start={6}>
                            <li>
                                <strong>Draw Details</strong>
                            </li>
                        </ol>
                        <p>
                            Random draws will be conducted on site through an instant-prize randomization function by a representative of the Sponsor or Administrator located in
                            Vancouver, British Columbia, Canada, from all eligible Entries received during the Contest Period (the “Draw”). The Draws will occur in accordance with
                            the schedule in section 2. The Prizes will be awarded instantly on site at the MFX Trailer. You will need to be in attendance to be selected. You are
                            only eligible to win a Prize once.
                        </p>
                        <ol start={7}>
                            <li>
                                <strong>Release and Skill Testing Question</strong>
                            </li>
                        </ol>
                        <p>
                            In order to be declared a winner, you must correctly answer without assistance a mathematical skill testing question and sign a Release form. Upon
                            verification of the completion and correctness of the answer to the skill-testing question, and execution of the Release, you may be declared a winner.
                        </p>
                        <ol start={8}>
                            <li>
                                <strong>Forfeit of Prize</strong>
                            </li>
                        </ol>
                        <p>
                            If you do not answer the skill testing question correctly, do not sign the Release, or otherwise fail to comply with these Contest Rules, then the Prize
                            will be forfeited.
                        </p>
                        <ol start={9}>
                            <li>
                                <strong>Use of Personal Information</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest and voluntarily providing your personal information as described in these Contest Rules, you agree to the collection, use and
                            disclosure by the Administrator, and its respective employees and/or authorized agents, of your personal information, for the purpose of administering
                            the Contest, including—but not limited to—contacting you with respect to the Contest if you are a selected entrant. Personally-identifiable information
                            will only be used to administer the Contest and for no other purpose, except as specifically outlined in these Contest Rules. Personally-identifiable
                            information will not be sold, shared or disclosed by the Administrator to any third party, other than to a third party engaged by the Administrator for
                            the purpose of administering the Contest, or if required by law with the exception that aggregated information may be used by or shared amongst the
                            Administrator and its subsidiaries and affiliates, or shared by the Administrator with third parties in accordance with our Privacy Commitment available
                            at https://www.sobeys.com/en/privacy-policy.
                        </p>
                        <ol start={10}>
                            <li>
                                <strong>Publicity Consent</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest, you agree that if you are determined to be a winner, the Administrator and the Sponsor may use your name, comments, voice,
                            likeness, municipality of residence, your contest Entry and photo submission and picture in any advertising, promotion or publicity carried out now or
                            in the future, in any media without compensation or notice, and you grant to the Administrator and the Sponsor any and all rights to such use. You agree
                            to cooperate with the Administrator and the Sponsor in arranging for photographs or other forms of publicity and to be available for photographs or
                            other forms of publicity on reasonable notice.
                        </p>
                        <ol start={11}>
                            <li>
                                {" "}
                                <strong>Limitation of Liability / Release</strong>
                            </li>
                        </ol>
                        <p>
                            By participating in the Contest, and as a condition of acceptance of the Prize, you, your heirs, executors, administrators, successors and assigns,
                            release and forever discharge and hold harmless the Sponsor and the Administrator, and each of their direct and indirect parent companies, affiliates,
                            subsidiaries, successors, assigns, agents, advisors, franchisees, shareholders, partners, representatives, their advertising, promotion and fulfillment
                            agencies and each of their respective employees, officers, directors, agents and representatives (collectively, the “Released Parties”), from and
                            against any and all losses, damages (including, without limitation, direct, indirect, incidental, consequential or punitive damages), rights, claims,
                            actions, causes of action, personal injury, property damage or death, including without limitation all costs and liabilities of any kind including legal
                            fees on a substantial indemnity scale, hereinafter called “Claims” and including without limitation any Claims arising from any act of negligence of the
                            Released Parties, that you now have, or may hereafter have against the Released Parties directly or indirectly resulting or arising from: (i)
                            participation in the Contest, your Entry and/or the awarding, acceptance, possession, use, or misuse or enjoyment of any Prize, and where applicable,
                            traveling to, preparing for, or participating in, any Contest-related or Prize-related event or activity; or (ii) the publicity rights granted to the
                            Sponsor and the Administrator. You specifically acknowledge that you understand that there may be serious risks of bodily injury, death, or property
                            damage associated with the acceptance, possession, use and/or misuse of the Prize and attendance at any event or participation in certain Prize-related
                            activities, and you voluntarily assume these risks. You also agree to indemnify, defend, and hold harmless the Released Parties from any and all Claims
                            in respect of the foregoing.
                        </p>
                        <ol start={12}>
                            <li>
                                <strong>Compliance with Rules</strong>
                            </li>
                        </ol>
                        <p>
                            By entering the Contest, you agree to abide by the entire Contest Rules and the terms and conditions under which the Prize is awarded. Any decision made
                            by the Sponsor and/or the Administrator in respect of this Contest shall be final.
                        </p>
                        <ol start={13}>
                            <li>
                                <strong>Technical Issues</strong>
                            </li>
                        </ol>
                        <p>
                            The Sponsor and Administrator are not responsible for any computer, online, telephone, hardware, software or technical limitations or malfunctions that
                            may occur (including but not limited to malfunctions that may affect the transmission or non-transmission of an Entry, or failure to receive an Entry),
                            nor for any incorrect or inaccurate information, whether caused by website users or by any of the equipment or programming associated with or utilized
                            in the Contest or by any technical or human error which may occur in the processing of Entries in the Contest, nor for any error, omission,
                            interruption, deletion, defect, delay in operation or transmission or receipt of Entry, communications line failure, theft or destruction or
                            unauthorized access to, or alteration of Entries; and, are not responsible for any problems, failures or technical malfunction of any telephone or
                            network lines, computer online systems, servers, providers, computer equipment, software, email, players, or browsers, on account of technical problems
                            or traffic congestion on the Internet, at any website or on account of any combination of the foregoing; and are not responsible for any injury or
                            damage to entrants or to any computer related injury or damage resulting from participating in downloading materials in this Contest.
                        </p>
                        <p>
                            In the event that, due to a printing, manufacturing, mechanical or other error of any kind or nature whatsoever, more Prizes are claimed than are
                            intended to be awarded according to these Contest Rules, Prizes will stop being awarded when the Administrator becomes aware of an error respecting the
                            number of Prizes and the Administrator reserves the right, in their sole and absolute discretion, to conduct a random draw from amongst all eligible and
                            approved Prize claimants whose claims have not yet been redeemed to award the remaining number of advertised Prizes. In no event will the Sponsor or
                            Administrator be liable for more than the stated number of Prizes contained in these Contest Rules.
                        </p>
                        <ol start={14}>
                            <li>
                                {" "}
                                <strong>Rights to Terminate/Modify/Suspend Contest</strong>
                            </li>
                        </ol>
                        <p>
                            The Administrator and Sponsor retain the right, in their absolute discretion, to suspend, modify or terminate any aspect of the Contest (including, but
                            not limited to these Contest Rules) at any time. The Administrator and Sponsor may make substitutions of equivalent kind or value in the event of the
                            unavailability of the Prize (or component thereof) for any reason whatsoever. The Administrator and Sponsor may terminate or withdraw this Contest at
                            any time by posting a notice on our website. If the Contest is terminated on any day prior to the normal Contest end date (the “Termination Date”), the
                            Administrator and Sponsor may determine the winners from all eligible Entries received as of 12:00PM PST on the Termination Date (which winners shall be
                            randomly selected in accordance with these Contest Rules).
                        </p>
                        <ol start={15}>
                            <li>
                                <strong>General Disqualification</strong>
                            </li>
                        </ol>
                        <p>
                            Any attempt made by an entrant or other individual or entity, to deliberately damage, manipulate or undermine the legitimate operation of the Contest,
                            including but not limited to any fraudulent claims, is a violation of the law. The Administrator and Sponsor reserve the right to seek remedies and
                            damages from any such individual or entity which makes any such attempt. Any entrant who, in the opinion of the Administrator, is determined to be
                            engaging in any of the foregoing activities will be immediately disqualified from the Contest.
                        </p>
                        <ol start={16}>
                            <li>
                                <strong>Role of Parties</strong>
                            </li>
                        </ol>
                        <p>Sobeys Capital Incorporated is the Sponsor and XMC Experiential Marketing is the Administrator of this Contest.</p>
                        <ol start={17}>
                            <li>
                                <strong>Trademark</strong>
                                <strong>s</strong>
                            </li>
                        </ol>
                        <p>All other Trademarks are the property of their respective owners.</p>
                        <ol start={18}>
                            <li>
                                <strong>Jurisdiction</strong>
                            </li>
                        </ol>
                        <p>
                            The Contest is governed by the laws of British Columbia and the laws of Canada applicable therein, and is subject to all applicable federal, provincial
                            and municipal laws and regulations and is void where prohibited by law. Participation constitutes your full and unconditional agreement to these Contest
                            Rules and the decisions of the Sponsor and Administrator, which are final and binding in all matters related to the Contest. By entering the Contest,
                            you agree that the courts of British Columbia, Canada shall have jurisdiction to entertain any action or other legal proceedings based on any provision
                            of this Contest’s Rules or the running of this Contest.
                        </p>
                    </>
                )}
            </div>
        </>
    );
};

export default Contest;
