import { useTranslation } from "react-i18next";
import disableBrowserHistory from "../../../helpers/utils";
import HeaderBA from "../../../Components/HeaderBA";
import { Link } from "react-router-dom";

const PrizeClaimed = () => {
    disableBrowserHistory();

    return (
        <>
            <HeaderBA />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="content">
                            <div style={{ textAlign: "center", marginTop: "5rem" }}>
                                <img src="/assets/images/check-ok.svg" alt="" width="80" />
                                <div style={{ marginTop: "2rem" }}>
                                    <h1>Prize awarded</h1>
                                    <p style={{ paddingTop: "1rem" }}>Please present the prize winner with their prize: One (1) Lunchbox</p>{" "}
                                </div>
                            </div>

                            <div className="action">
                                <Link to="/portal" className="btn" style={{ minWidth: "10rem", margin: "0 auto" }} onClick={() => {}}>
                                    Continue
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrizeClaimed;
