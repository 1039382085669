
import { Outlet } from "react-router-dom"
import { useRequiredCampaignState } from "../../helpers/campaignStates"
import Header from "../../Components/Header"
import Footer from "../../Components/Footer"

export default function Open() {
  useRequiredCampaignState("open")

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}
