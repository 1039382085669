function OptionsProvinces() {
    const provincesEn = [
        { pr: "AB", province: "Alberta" },
        { pr: "BC", province: "British Columbia" },
        { pr: "MB", province: "Manitoba" },
        { pr: "NB", province: "New Brunswick" },
        { pr: "NL", province: "Newfoundland and Labrador" },
        { pr: "NT", province: "Northwest Territories" },
        { pr: "NS", province: "Nova Scotia" },
        { pr: "NU", province: "Nunavut" },
        { pr: "ON", province: "Ontario" },
        { pr: "PE", province: "Prince Edward Island" },
        { pr: "QC", province: "Quebec" },
        { pr: "SK", province: "Saskatchewan" },
        { pr: "YT", province: "Yukon" },
    ];

    return provincesEn.map(function ({ pr, province }: { pr: string; province: string }) {
        return (
            <option value={pr} key={province}>
                {province}
            </option>
        );
    });
}

export default OptionsProvinces;
