import { isAfterDate } from "../../../helpers/utils";

function DeclarationEn() {
    return (
        <>
            <h5 style={{ textAlign: "center", fontSize: "16px" }}>
                <strong>CONTEST PRIZE WINNER RELEASE</strong>
                <p>SAFEWAY 53’ MFX FTD LUNCHBOX CONTEST 2024</p> <p>(the &ldquo;Contest&rdquo;)</p>
            </h5>

            <div className="rules">
                {isAfterDate() ? (
                    <>
                        <ol>
                            <li>
                                <strong> PARTIES</strong>
                            </li>
                        </ol>
                        <p>
                            For the purposes of this Release, the term “Contest Entities” shall mean XMC Experiential Marketing (the “Administrator”), and Sobeys Capital
                            Incorporated (the "Sponsor"), and each of their direct and indirect parent companies, subsidiaries, affiliates, franchisees, successors, assigns,
                            advertising agencies, sponsors, advisors and representatives and each of their employees, directors, officers, representatives and agents.&nbsp;
                        </p>
                        <ol start={2}>
                            <li>
                                <strong> PRIZE(S)</strong>
                            </li>
                        </ol>
                        <p>
                            For the entire Contest Period there are two hundred (200) prizes to be won (each a “Prize(s)”). Each Prize consists of one (1) Safeway branded
                            lunchbox(included inside: two (2) note pads, one (1) Ice pack). The approximate retail value of each Prize is $19.00 (CAD).
                        </p>
                        <p>
                            You understand that the Prize is not transferable and must be accepted as awarded with no substitutions in cash or otherwise, except at Sponsor sole
                            discretion. You understand that you are responsible for all fees and expenses associated with the Prize that are not expressly described as being
                            included as part of the Prize. You understand that the Sponsor has the right to substitute a prize of equivalent or greater value if the Prize cannot be
                            awarded as described. Any difference between approximate retail value and the actual value of the Prize as taken will not be awarded.
                        </p>
                        <ol start={3}>
                            <li>
                                <strong> COMPLIANCE WITH CONTEST RULES</strong>
                            </li>
                        </ol>
                        <p>You, the undersigned, confirm and agree as follows:</p>
                        <ul>
                            <li>I have read, understand and complied with the Contest Rules and have agreed to be bound by them;</li>
                            <li>I was the age of majorityin the province where I reside on the date I entered the Contest;</li>
                            <li>I am a legal resident of the Province of Alberta;</li>
                            <li>
                                I am not an employee, officer or director of any of the Contest Entities, nor am I domiciled with, or an immediate family member of, an employee,
                                officer or director of the any of the Contest Entities; and
                            </li>
                            <li>I have correctly answered, unaided, the time-limited, mathematical skill-testing question attached as Schedule “A” to this Release.</li>
                        </ul>
                        <ol start={4}>
                            <li>
                                <strong> PUBLICITY RELEASE</strong>
                            </li>
                        </ol>
                        <p>
                            You, the undersigned, agree that your name, comments, voice, likeness, municipality of residence and picture may be published and used for advertising,
                            marketing, public relations, and other promotional purposes by the Contest Entities and their direct and indirect affiliates and subsidiaries, without
                            notice or any payment or other compensation to you.
                        </p>
                        <ol start={5}>
                            <li>
                                <strong> LIABILITY RELEASE AND INDEMNIFICATION </strong>
                            </li>
                        </ol>
                        <p>
                            For good and valuable consideration (including the awarding and receipt of the Prize), you, the undersigned, hereby release the Contest Entities, from
                            any and all claims, actions, causes or action, complaints, suits, damages, costs, expenses, solicitor fees and liabilities of any kind (collectively
                            “Claims”) including, without limitation, any Claims arising from any negligence of the Contest Entities, which your ever had, now have or hereafter will
                            or may have, or which your heirs, administrators, executors, successors or assignees or any of them will or may have, for or by reason of any cause,
                            matter or thing arising from or relating in any manner to, the Contest, your participation therein, the awarding of a Prize, your participation in the
                            Prize, or your acceptance, possession, use or misuse of the Prize or the products you obtain as a result thereof, or (where applicable) you travelling
                            to, preparing for, or participating in, any Contest or Prize related trip or activity, or the publicity rights you have granted hereunder, or any
                            misrepresentation by you contained in this Release. You also agree to indemnify, defend, and hold harmless the Contest Entities from any and all Claims
                            in respect of the foregoing. You understand that there may be serious risk of bodily injury, death, and/or property damage associated with the
                            acceptance, possession, use and/or misuse of some prizes, and you voluntarily agree to assume these risks and to fully and completely release and hold
                            harmless the Contest Entities from any and all liability for any such bodily injury, death, and/or property damage resulting therefrom.
                        </p>
                        <ol start={6}>
                            <li>
                                <strong> OTHER IMPORTANT TERMS</strong>
                            </li>
                        </ol>
                        <p>
                            You understand that you are required to pay any title, license, insurance, registration fees, or taxes associated with the Prize. You agree that any
                            claim or dispute with respect to the Contest, the Prize, or this Agreement shall be governed by the laws of the Province of Alberta and any proceedings
                            in relation to any such claim or dispute shall be resolved exclusively by courts of the Province of Alberta. You agree that if any portion of this
                            Agreement is determined to be unenforceable by a court of law, all other parts of this Agreement shall remain in full force and effect, and that the
                            conditions and covenants herein shall be binding on your heirs, administrators, executors, successors and assigns.
                        </p>
                        <ol start={7}>
                            <li>
                                <strong> ACCEPTANCE OF PRIZE</strong>
                            </li>
                        </ol>
                        <p>
                            You will accept the Prize as is. You understand that the Contest Entities have not made any warranty, representation or guarantee (including, but not
                            limited to any warranty of merchantability or fitness for a particular purpose or use), whether written or verbal, express or implied, regarding the
                            Prize, or any element thereof, or substitution therefore.
                        </p>
                        <p>
                            You understand that this Release affects legal rights you may have. You understand that you have the opportunity to take this Release away and review it
                            with your legal counsel for up to three (3) days before signing (although you acknowledge that you must first in any event complete the skill testing
                            question on the attached Schedule “A” before a representative of the Administrator and have either done so, or hereby waive your right to do so).
                        </p>
                        <p>I have carefully read and understood this Release and agree to be bound by it.</p>
                    </>
                ) : (
                    <>
                        <ol>
                            <li>
                                <strong> PARTIES</strong>
                            </li>
                        </ol>
                        <p>
                            For the purposes of this Release, the term “Contest Entities” shall mean XMC Experiential Marketing (the “Administrator”), and Sobeys Capital
                            Incorporated (the "Sponsor"), and each of their direct and indirect parent companies, subsidiaries, affiliates, franchisees, successors, assigns,
                            advertising agencies, sponsors, advisors and representatives and each of their employees, directors, officers, representatives and agents.&nbsp;
                        </p>
                        <ol start={2}>
                            <li>
                                <strong> PRIZE(S)</strong>
                            </li>
                        </ol>
                        <p>
                            For the entire Contest Period there are six hundred (600) prizes to be won (each a “Prize(s)”). Each Prize consists of one (1) Safeway branded lunchbox
                            (included inside: two (2) note pads, one (1) Ice pack). The approximate retail value of each Prize is $19.00 (CAD).
                        </p>
                        <p>
                            You understand that the Prize is not transferable and must be accepted as awarded with no substitutions in cash or otherwise, except at Sponsor sole
                            discretion. You understand that you are responsible for all fees and expenses associated with the Prize that are not expressly described as being
                            included as part of the Prize. You understand that the Sponsor has the right to substitute a prize of equivalent or greater value if the Prize cannot be
                            awarded as described Any difference between approximate retail value and the actual value of the Prize as taken will not be awarded.
                        </p>
                        <ol start={3}>
                            <li>
                                <strong> COMPLIANCE WITH CONTEST RULES</strong>
                            </li>
                        </ol>
                        <p>You, the undersigned, confirm and agree as follows:</p>
                        <ul>
                            <li>I have read, understand and complied with the Contest Rules and have agreed to be bound by them;</li>
                            <li>I was the age of majorityin the province where I reside on the date I entered the Contest;</li>
                            <li>I am a legal resident of the Province of Vancouver;</li>
                            <li>
                                I am not an employee, officer or director of any of the Contest Entities, nor am I domiciled with, or an immediate family member of, an employee,
                                officer or director of the any of the Contest Entities; and
                            </li>
                            <li>I have correctly answered, unaided, the time-limited, mathematical skill-testing question attached as Schedule “A” to this Release.</li>
                        </ul>
                        <ol start={4}>
                            <li>
                                <strong> PUBLICITY RELEASE</strong>
                            </li>
                        </ol>
                        <p>
                            You, the undersigned, agree that your name, comments, voice, likeness, municipality of residence and picture may be published and used for advertising,
                            marketing, public relations, and other promotional purposes by the Contest Entities and their direct and indirect affiliates and subsidiaries, without
                            notice or any payment or other compensation to you.
                        </p>
                        <ol start={5}>
                            <li>
                                <strong> LIABILITY RELEASE AND INDEMNIFICATION </strong>
                            </li>
                        </ol>
                        <p>
                            For good and valuable consideration (including the awarding and receipt of the Prize), you, the undersigned, hereby release the Contest Entities, from
                            any and all claims, actions, causes or action, complaints, suits, damages, costs, expenses, solicitor fees and liabilities of any kind (collectively
                            “Claims”) including, without limitation, any Claims arising from any negligence of the Contest Entities, which your ever had, now have or hereafter will
                            or may have, or which your heirs, administrators, executors, successors or assignees or any of them will or may have, for or by reason of any cause,
                            matter or thing arising from or relating in any manner to, the Contest, your participation therein, the awarding of a Prize, your participation in the
                            Prize, or your acceptance, possession, use or misuse of the Prize or the products you obtain as a result thereof, or (where applicable) you travelling
                            to, preparing for, or participating in, any Contest or Prize related trip or activity, or the publicity rights you have granted hereunder, or any
                            misrepresentation by you contained in this Release. You also agree to indemnify, defend, and hold harmless the Contest Entities from any and all Claims
                            in respect of the foregoing. You understand that there may be serious risk of bodily injury, death, and/or property damage associated with the
                            acceptance, possession, use and/or misuse of some prizes, and you voluntarily agree to assume these risks and to fully and completely release and hold
                            harmless the Contest Entities from any and all liability for any such bodily injury, death, and/or property damage resulting therefrom.
                        </p>
                        <ol start={6}>
                            <li>
                                <strong> OTHER IMPORTANT TERMS</strong>
                            </li>
                        </ol>
                        <p>
                            You understand that you are required to pay any title, license, insurance, registration fees, or taxes associated with the Prize. You agree that any
                            claim or dispute with respect to the Contest, the Prize, or this Agreement shall be governed by the laws of the Province of Alberta and any proceedings
                            in relation to any such claim or dispute shall be resolved exclusively by courts of the Province of Alberta. You agree that if any portion of this
                            Agreement is determined to be unenforceable by a court of law, all other parts of this Agreement shall remain in full force and effect, and that the
                            conditions and covenants herein shall be binding on your heirs, administrators, executors, successors and assigns.
                        </p>
                        <ol start={7}>
                            <li>
                                <strong> ACCEPTANCE OF PRIZE</strong>
                            </li>
                        </ol>
                        <p>
                            You will accept the Prize as is. You understand that the Contest Entities have not made any warranty, representation or guarantee (including, but not
                            limited to any warranty of merchantability or fitness for a particular purpose or use), whether written or verbal, express or implied, regarding the
                            Prize, or any element thereof, or substitution therefore.
                        </p>
                        <p>
                            You understand that this Release affects legal rights you may have. You understand that you have the opportunity to take this Release away and review it
                            with your legal counsel for up to three (3) days before signing (although you acknowledge that you must first in any event complete the skill testing
                            question on the attached Schedule “A” before a representative of the Administrator and have either done so, or hereby waive your right to do so).
                        </p>
                        <p>I have carefully read and understood this Release and agree to be bound by it.</p>
                    </>
                )}
            </div>
        </>
    );
}

export default DeclarationEn;
